
<template>
  <div >
    <div class="vx-row">
        <div class="vx-col lg:w-2/3 w-full">
            <vs-alert
                color="success"
                class="mb-3"
                title="No Chat!"
                closable
                close-icon="cancel"
                :active.sync="isShowAlert"
                >
                <span>Please go to <router-link class="text-warning" to="/orders"> your orders </router-link>, select an order and send us message under it</span>
            </vs-alert>
        </div>
    </div>
  </div>
</template>

<script>
// Cell Renderer
export default {
  name:"chat-redirect",
  data() {
    return {
      isShowAlert: true
    }
  },
  computed: {

  },
  methods: {

  },
}

</script>

<style lang="scss">

</style>
